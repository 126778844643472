<template>
  <div class="container">
    <nav class="navbar navbar-expand-lg">
      <div class="container-fluid">
        <a class="navbar-brand" href="#"><img src="../assets/logos/logo_navbar.svg" alt=""></a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-lg-end justify-content-center" id="navbarNav">
          <ul class="navbar-nav text-center text-4">
            <li class="nav-item">
              <router-link class="nav-link p-16" aria-current="page" :to="{name: 'home', hash: '#'+'beneficios'}">Nosotros</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link p-16" aria-current="page" :to="{name: 'home', hash: '#'+'como-funciona'}">Cómo Funciona</router-link>
              
            </li>
            <li class="nav-item">
              <router-link class="nav-link p-16" aria-current="page" :to="{name: 'home', hash: '#'+'servicios'}">Servicios</router-link>
              
            </li>
            <!--<li class="nav-item">
              <router-link class="nav-link p-16" aria-current="page" :to="{name: 'home', hash: '#'+'testimonios'}">Testimonios</router-link>
            </li>-->
            <li class="nav-item">
              <router-link class="nav-link p-16" aria-current="page" :to="{name: 'home', hash: '#'+'faqs'}">Preguntas Frecuentes</router-link>
            </li>
            <li >
              <BtButton 
                :colorBtn="'pink-btn'" 
                :buttonText="'reservar'" 
                class="nav-btn"
                data-tally-open="mZJkbB" 
                data-tally-layout="modal" 
                data-tally-width="800"/>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
  
</template>

<script>

import BtButton from '../components/BtButton.vue'

export default {
  name: 'NavBar',
  props: {
    msg: String
  },
  components: {
    BtButton
  }
}
</script>

