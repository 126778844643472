<template>
  <div class="container-fluid pre-navbar">
    <router-link to="#equipobt">¡Quiero ofrecer una experiencia!</router-link>
  </div>
</template>

<script>

export default {
  name: 'NavBar',

}
</script>