<template>
  <PreNavbar class="d-lg-none"/>  
  <NavBar/>
  <router-view/>
  <FooterComp/>
</template>

<script>
import PreNavbar from './components/PreNavbar.vue'
import NavBar from './components/NavBar.vue'
import FooterComp from './components/FooterComp.vue'

export default {
  name: 'App',
  components: {
    PreNavbar,
    NavBar,
    FooterComp
  }
}

</script>

