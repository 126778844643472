<template>
  <div class="accordion-item pink-box">
    <h2 class="accordion-header">
      <button class="accordion-button poppins-4" type="button" data-bs-toggle="collapse" :data-bs-target="'#' + collapseId" aria-expanded="isOpen" :aria-controls="collapseId" @click="toggleAccordion">
        <p class="mb-0">¿O una actividad?</p>
        <span class="accordion-icon poppins-2">
          <span :class="{ rotated: isOpen }">{{ isOpen ? '-' : '+' }}</span>
        </span>
      </button>
    </h2>
    <div :id="collapseId" class="accordion-collapse collapse" data-bs-parent="#accordionExample" @transitionend="onTransitionEnd">
      <div class="accordion-body text-3 text-start pt-3">
        <ul>
          <li v-for="(otro, index) in otrasactividades" :key="index">{{otro}}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'AccordionOtro',
  data() {
    return {
      isOpen: false,
      collapseId: 'collapseTwo'
    };
  },
  computed: {
    ...mapState(['otrasactividades'])
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
      this.updateCollapseClass();
    },
    updateCollapseClass() {
      const collapseElement = document.getElementById(this.collapseId);
      if (this.isOpen) {
        collapseElement.classList.add('collapsing');
        collapseElement.classList.remove('collapse');
        setTimeout(() => {
          collapseElement.classList.remove('collapsing');
          collapseElement.classList.add('collapse', 'show');
        }, 350); // Duración de la transición de Bootstrap en ms
      } else {
        collapseElement.classList.add('collapsing');
        collapseElement.classList.remove('collapse', 'show');
        setTimeout(() => {
          collapseElement.classList.remove('collapsing');
          collapseElement.classList.add('collapse');
        }, 350); // Duración de la transición de Bootstrap en ms
      }
    },
    onTransitionEnd(event) {
      if (!this.isOpen) {
        event.target.classList.remove('show');
      }
    }
  }
}
</script>