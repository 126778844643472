<template>
  <div>
    <div class="accordion" id="accordionExample">
      <div class="accordion-item">
        <h4 class="accordion-header">
          <button class="accordion-button collapsed text-2" type="button" data-bs-toggle="collapse" data-bs-target="#collapseUno" aria-expanded="false" aria-controls="collapseUno">
            <img src="../assets/curves/green_icon_1.svg" alt="" class="curve-icon">
            ¿Cómo puedo solicitar un taller o actividad para mi empresa?
          </button>
        </h4>
        <div id="collapseUno" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
          <div class="accordion-body text-start">
            <p class="text-4">
              Para solicitar un taller o actividad, simplemente póngase en contacto con nosotros a través de nuestro formulario de contacto en la página web, o envíenos un correo electrónico a correo@breaktime.com. Nuestro equipo se pondrá en contacto con usted para coordinar los detalles.
            </p>
          </div>
        </div>
      </div>

      <div class="accordion-item">
        <h4 class="accordion-header">
          <button class="accordion-button collapsed text-2" type="button" data-bs-toggle="collapse" data-bs-target="#collapseDos" aria-expanded="false" aria-controls="collapseDos">
            <img src="../assets/curves/yellow_icon_1.svg" alt="" class="curve-icon">
            ¿Qué duración tienen los talleres y actividades?
          </button>
        </h4>
        <div id="collapseDos" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
          <div class="accordion-body text-start">
            <p class="text-4">
              La duración de nuestros talleres y actividades varía dependiendo del tipo de servicio. Generalmente, para los talleres dentro del horario laboral las sesiones pueden durar entre 45 minutos a 1 hora, en el caso de que se realicen actividades fuera del horario laboral, el servicio puede durar entre 1 a 2 horas. Podemos ajustar la duración según las necesidades específicas de su empresa.
            </p>
          </div>
        </div>
      </div>

      <div class="accordion-item">
        <h4 class="accordion-header">
          <button class="accordion-button collapsed text-2" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTres" aria-expanded="false" aria-controls="collapseTres">
            <img src="../assets/curves/pink_icon_1.svg" alt="" class="curve-icon">
            ¿Ofrecen servicios personalizados según las necesidades de mi empresa? 
          </button>
        </h4>
        <div id="collapseTres" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
          <div class="accordion-body text-start">
            <p class="text-4">
              Sí, nos especializamos en personalizar nuestros servicios para satisfacer las necesidades específicas de cada cliente. Podemos diseñar un programa de actividades que se alinee con los objetivos y la cultura de su empresa.
            </p>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h4 class="accordion-header">
          <button class="accordion-button collapsed text-2" type="button" data-bs-toggle="collapse" data-bs-target="#collapseCuatro" aria-expanded="false" aria-controls="collapseCuatro">
            <img src="../assets/curves/green_icon_1.svg" alt="" class="curve-icon">
            ¿Los talleres y actividades se realizan en el lugar de trabajo o en otro sitio? 
          </button>
        </h4>
        <div id="collapseCuatro" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
          <div class="accordion-body text-start">
            <p class="text-4">
              Ofrecemos flexibilidad en la ubicación de nuestros servicios. Podemos realizar los talleres y actividades en el lugar de trabajo o en lugares externos según su preferencia. También contamos con opciones para actividades al aire libre.
            </p>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h4 class="accordion-header">
          <button class="accordion-button collapsed text-2" type="button" data-bs-toggle="collapse" data-bs-target="#collapseCinco" aria-expanded="false" aria-controls="collapseCinco">
            <img src="../assets/curves/yellow_icon_1.svg" alt="" class="curve-icon">
            ¿Qué medidas de seguridad implementan para las actividades al aire libre? 
          </button>
        </h4>
        <div id="collapseCinco" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
          <div class="accordion-body text-start">
            <p class="text-4">
              Nos tomamos muy en serio la seguridad de los participantes. Todas nuestras actividades al aire libre se realizan bajo la supervisión de expertos y se cumplen todas las normativas de seguridad pertinentes. Además, proporcionamos el equipo necesario y capacitamos a los participantes en las medidas de seguridad antes de comenzar cualquier actividad.
            </p>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h4 class="accordion-header">
          <button class="accordion-button collapsed text-2" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeis" aria-expanded="false" aria-controls="collapseSeis">
            <img src="../assets/curves/pink_icon_1.svg" alt="" class="curve-icon">
            ¿Cuál es el proceso de pago para contratar sus servicios?
          </button>
        </h4>
        <div id="collapseSeis" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
          <div class="accordion-body text-start">
            <p class="text-4">
              Una vez que confirme la solicitud de un taller o actividad, le enviaremos una cotización detallada. Tras la aceptación de la cotización, se generará una factura y podrá realizar el pago mediante transferencia bancaria o tarjeta de crédito. Posteriormente, procederemos a coordinar y ejecutar el servicio.
            </p>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h4 class="accordion-header">
          <button class="accordion-button collapsed text-2" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSiete" aria-expanded="false" aria-controls="collapseSiete">
            <img src="../assets/curves/green_icon_1.svg" alt="" class="curve-icon">
            ¿Qué pasa si necesito cancelar o reprogramar un taller o actividad?
          </button>
        </h4>
        <div id="collapseSiete" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
          <div class="accordion-body text-start">
            <p class="text-4">
              Entendemos que pueden surgir imprevistos. Si necesita cancelar o reprogramar, le pedimos que nos informe con al menos 48 horas de anticipación. Haremos nuestro mejor esfuerzo para reprogramar la actividad según su conveniencia.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccordionDudas"
};
</script>

