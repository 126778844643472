<template>
  <div class="accordion-item green-box">
    <h2 class="accordion-header">
      <button class="accordion-button poppins-4" type="button" data-bs-toggle="collapse" :data-bs-target="'#' + collapseId" aria-expanded="isOpen" :aria-controls="collapseId" @click="toggleAccordion">
        <p class="mb-0">¿Te parece un taller?
          <span class="accordion-icon poppins-2">
            <span v-if="isOpen">-</span>
            <span v-else>+</span>
          </span>
        </p>
      </button>
    </h2>
    <div :id="collapseId" class="accordion-collapse collapse" data-bs-parent="#accordionExample" @transitionend="onTransitionEnd">
      <div class="accordion-body text-3 text-start pt-3">
        <ul>
          <li v-for="(taller, index) in talleres" :key="index">{{taller}}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'AccordionTaller',
  data() {
    return {
      isOpen: false,
      collapseId: 'collapseOne'
    };
  },
  computed: {
    ...mapState(['talleres'])
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
      this.updateCollapseClass();
    },
    updateCollapseClass() {
      const collapseElement = document.getElementById(this.collapseId);
      if (this.isOpen) {
        collapseElement.classList.add('collapsing');
        collapseElement.classList.remove('collapse');
        setTimeout(() => {
          collapseElement.classList.remove('collapsing');
          collapseElement.classList.add('collapse', 'show');
        }, 350); // Duración de la transición de Bootstrap en ms
      } else {
        collapseElement.classList.add('collapsing');
        collapseElement.classList.remove('collapse', 'show');
        setTimeout(() => {
          collapseElement.classList.remove('collapsing');
          collapseElement.classList.add('collapse');
        }, 350); // Duración de la transición de Bootstrap en ms
      }
    },
    onTransitionEnd(event) {
      if (!this.isOpen) {
        event.target.classList.remove('show');
      }
    }
  }
}
</script>
<!--<template>
  <div class="accordion-item green-box">
    <h2 class="accordion-header">
      <button class="accordion-button poppins-4" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="isOpen" aria-controls="collapseOne" @click="toggleAccordion">
        <p class="mb-0">¿Te parece un taller?
          <span class="accordion-icon poppins-2">
          <span v-if="isOpen">-</span>
          <span v-else>+</span>
        </span>
        </p>
        
      </button>
    </h2>
    <div :id="collapseId" class="accordion-collapse" data-bs-parent="#accordionExample" :class="{'collapse show': isOpen, 'collapse': !isOpen}">
      <div class="accordion-body text-3 text-start pt-3">
        <ul>
          <li v-for="(taller, index) in talleres" :key="index">{{taller}}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex';

export default {
  name: 'AccordionTaller',
  data() {
    return {
      isOpen: false,
      collapseId: 'collapseOne'
    };
  },
  computed: {
    ...mapState(['talleres'])
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    }
  }
}


</script>-->