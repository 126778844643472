<template>
  <footer>
    <div class="invertir-x">
      <img src="../assets/bg/white-bg.png" alt="">
    </div>
    <div class="container">
      <div class="text-center">
        <img src="../assets/logos/logo_footer.png" alt="" class="logo-footer">
      </div>
      <div class="text-end texto-footer">
        <p class="text-4"><a href="mailto:contacto@breaktime.cl" target="_blank">contacto@breaktime.cl</a></p>
        <div class="rrss">
          <a href="https://www.instagram.com/breaktime.cl/" target="_blank"><img src="../assets/icons/insta_icon.png" alt=""></a>
          <a href="https://www.linkedin.com/in/break-time-1505b9313/" target="_blank"><img src="../assets/icons/lkdn_icon.png" alt=""></a>
          <a href="https://www.tiktok.com/@breaktime.cl?_t=8nAdDdwlgko&_r=1" target="_blank"><img src="../assets/icons/tiktok_icon.png" alt=""></a>
        </div>
      </div>
      <hr>
      <p class="text-5 pb-4 text-lg-end text-center">Diseñado y Desarrollado por <a href="https://vanilla.cl" target="_blank">Vanilla Diseño</a></p>
    </div>
    <div class="curve-cont d-lg-block d-none">
      <img src="../assets/curves/pink_curve.svg" alt="">
    </div>
  </footer>
</template>

<script>

export default {
  name: "FooterComp"
}

</script>