import { createStore } from 'vuex'
import talleres from './talleres.json'
import otrasactividades from './otrasactividades.json'
import testimonios from './testimonios.json'
import dudas from './dudas.json'


export default createStore({
  state: {
    talleres: talleres,
    otrasactividades: otrasactividades,
    testimonios: testimonios,
    dudas: dudas,
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
