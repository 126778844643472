import { createApp } from 'vue';
import App from './App.vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import './assets/css/sass/style.css'; 
import store from './store';
import router from './router';
import AOS from 'aos';
import 'aos/dist/aos.css'; 

AOS.init();
createApp(App).use(store).use(router).mount('#app')
