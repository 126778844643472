<template>
  <button 
    type="button"
    class="btn btn-vertical" 
    :class="colorBtn">
    <router-link aria-current="page" :to="router">{{buttonText}}</router-link>
  </button>
</template>

<script>

export default {
  name: 'BtButtonVertical',
  props: {
    router: {
      type: String,
    },
    buttonText: {
      type: String,
      default: 'Button'
    },
    colorBtn: {
      type: String,
      default: 'pink-btn'
    },
  }
}
</script>