<template>
  <!--<WhatsAppBtn class="whatsapp fixed-button" :v-if="showButton"/>-->
  <BtButtonVertical :colorBtn="'green-btn'" :buttonText="'¡QUIERO  OFRECER UNA EXPERIENCIA!'" :router="'#equipobt'" class="d-lg-block d-none"/>
  <div class="home">
    <header>
      <div class="container">
        <div class="row"
            data-aos="fade-right" 
            data-aos-easing="ease-in-sine" 
            data-aos-offset="300" 
            data-aos-duration="700">
          <div 
            class="col-lg-5 first-col-header text-start"       
            >
            <h1 class="poppins-1">Descubre el poder de una <span class="sed-1-green">pausa perfecta</span></h1>
            <p class="text-1">Experiencias de bienestar y conexión con tu equipo de trabajo.</p>
            <BtButton 
            :colorBtn="'pink-btn'" 
            :buttonText="'Reserva tu experiencia ahora'"
            data-tally-open="mZJkbB" 
            data-tally-layout="modal" 
            data-tally-width="800"
            />
          </div>
          <div class="col-lg-7">
            <img src="../assets/img/hero_img.png" alt="" class="hero-img img-fluid">
          </div>
        </div>
      </div>
      <div class="bg-turq-cont">
        <img src="../assets/bg/turq-light-bg.png" alt="">
      </div>
    </header>
    <div class="turq-light-bg">
      <div id="beneficios" class="container">
        <div class="row text-center">
          <div 
          class="col-lg-4"
          data-aos="zoom-in" 
          data-aos-duration="1000">
            <img src="../assets/icons/value_img_1.png" alt="">
            <p class="text-2">Ofrecemos <span class="text-2-green">talleres y actividades </span>de alta calidad.</p>
          </div>
          <div 
          class="col-lg-4"
          data-aos="zoom-in" 
          data-aos-duration="1000">
            <img src="../assets/icons/value_img_2.png" alt="">
            <p class="text-2">Entregamos un <span class="text-2-green">servicio cercano </span>y personalizado.</p>
          </div>
          <div 
          class="col-lg-4"
          data-aos="zoom-in" 
          data-aos-duration="1000">
            <img src="../assets/icons/value_img_3.png" alt="">
            <p class="text-2">Nos comprometemos con <span class="text-2-green">aumentar tu bienestar</span>.</p>
          </div>
        </div>
      </div>
      <div id="somos" class="mx-0">
        <div class="curve-green-cont d-lg-block d-none">
          <img src="../assets/curves/green_curve.svg" alt="">
        </div>
        <div class="container">
          <div class="row">
            <div 
            class="col-lg-5 text-start"
            data-aos="fade-right" 
            data-aos-easing="ease-in-sine" 
            data-aos-offset="300" 
            data-aos-duration="700">
              <h2 class="poppins-2 pb-3">Recarga energías y fortalece <span class="sed-2-pink">tu equipo</span></h2>
              <p class="text-1">¿Estás estresado en el trabajo? ¿Pasas todo el día sentado sin moverte? ¿No conoces a tus compañeros de equipo? ¿No tienes pausas que realmente te recarguen? </p>
              <p class="text-3">Este estilo de vida te lleva a un deterioro de tu salud mental y afecta tu productividad. ¡Es hora de cambiar eso! <span class="text-3-green">Somos expertos en crear experiencias de team building y bienestar.</span> Nuestra misión es ayudarte a recargar energías y fortalecer lazos con tu equipo, todo mientras disfrutas de una experiencia única con BreakTime.</p>
            </div>
            <div class="col-lg-7 somos-img-cont">
              <div>
                <img src="../assets/img/somos_img.png" alt="" class="img-fluid pareja">
              </div>
              <div class="curve-pink-cont d-lg-block d-none">
                <img src="../assets/curves/pink_curve_2.svg" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="bg-white-cont">
          <img src="../assets/bg/white-bg.png" alt="">
        </div>
      </div>    
    </div>
    <div id="como-funciona" class="container">
      <div class="text-center">
        <h2 class="poppins-2 py-5">¿Cómo <span class="sed-2-green">funciona</span>?</h2>
      </div>
      <div class="row"
          data-aos="fade-right" 
          data-aos-easing="ease-in-sine" 
          data-aos-offset="300" 
          data-aos-duration="700">
        <div class="col-lg-6 text-lg-end">
          <img src="../assets/img/step_img_1.png" alt="" class="img-fluid">
        </div>
        <div class="col-lg-6 text-start">
          <p class="sed-3-pink">1.</p>
          <h3 class="poppins-4">Elegimos el tipo de pausa según tus necesidades</h3>
          <p class="text-3">Analizamos la situación actual de tu equipo junto con tus expectativas y con ello elaboramos una propuesta.</p>
        </div>
      </div>
      <div class="row"
        data-aos="fade-left" 
        data-aos-easing="ease-in-sine" 
        data-aos-offset="300" 
        data-aos-duration="700">
        <div class="col-lg-6 text-lg-start order-lg-1">
          <img src="../assets/img/step_img_2.png" alt="" class="img-fluid">
        </div>
        <div class="col-lg-6 text-start order-lg-0">
          <p class="sed-3-pink">2.</p>
          <h3 class="poppins-4">Agendamos tu experiencia BreakTime</h3>
          <p class="text-3">Revisamos disponibilidad de nuestro equipo según la actividad seleccionada y agendamos en la fecha que más te acomode.</p>
        </div>
      </div>
      <div class="row"
        data-aos="fade-right" 
        data-aos-easing="ease-in-sine" 
        data-aos-offset="00" 
        data-aos-duration="700">
        <div class="col-lg-6 text-lg-end">
          <img src="../assets/img/step_img_3.png" alt="" class="img-fluid">
        </div>
        <div class="col-lg-6 text-start">
          <p class="sed-3-pink">3.</p>
          <h3 class="poppins-4">Sólo disfrutar, desconectarte y renovar energías</h3>
          <p class="text-3">Relájate y conéctate con tu equipo en un ambiente único. Retoma tus tareas con más energía y motivación.</p>
        </div>
      </div>
    </div>
    <div class="light-yellow-bg">
      <div class="invertir-x">
        <img src="../assets/bg/white-bg.png" alt="">
      </div>
      <div id="servicios" class="container">
        <div class="row">
          <div 
            class="col-lg-5 text-start"
            data-aos="fade-right" 
            data-aos-easing="ease-in-sine" 
            data-aos-offset="300" 
            data-aos-duration="1000">
            <h2 class="poppins-2">Elige la <span class="sed-2-pink">pausa perfecta</span> para tu equipo</h2>
            <p class="text-3">Te ofrecemos talleres y actividades enfocados y personalizados para <span class="text-3-green">generar espacios de recreación, relajación y distensión en tu equipo, </span>tanto dentro como fuera del trabajo.</p>
            <p class="text-3">Nos dedicamos a ser expertos en fortalecer la salud mental y generar un buen ambiente laboral.</p>
          </div>
          <div class="col-lg-7">
            <AccordionTaller data-aos="zoom-in" data-aos-duration="1000"/>
            <AccordionOtro data-aos="zoom-in" data-aos-duration="1000"/>
          </div>
        </div>
      </div>
      <div class="bg-white-cont">
        <img src="../assets/bg/white-bg.png" alt="">
      </div>
    </div>
    <div id="testimonios">
      <div class="curve-cont d-lg-block d-none">
        <img src="../assets/curves/yellow_curve.svg" alt="">
      </div>
      <div class="container text-center"> 
        <h3 class="poppins-2">"Experimenta <span class="sed-2-pink">beneficios tangibles</span> en tu equipo, mejora en el <span class="sed-2-green">estado de ánimo</span>, mayor <span class="sed-2-green">energía</span> y una sensación de <span class="sed-2-green">bienestar general</span>"</h3>
        <!--<h2 class="poppins-2">Ellos ya descubrieron su <br><span class="sed-2-green">pausa perfecta</span></h2>
        <CarouselTestimonios/>-->
      </div>
    </div>
    <div class="light-grey-bg">
      <div class="invertir-x-y">
        <img src="../assets/bg/white-bg.png" alt="">
      </div>
      <div id="cta" class="text-start">
        <div class="container">
          <div class="row">
            <div 
              class="col-lg-7 col-10"
              data-aos="fade-right" 
              data-aos-easing="ease-in-sine" 
              data-aos-offset="300" 
              data-aos-duration="1000">
              <h2 class="poppins-2">¡Reserva tu experiencia <span class="sed-2-pink">BreakTime!</span></h2>
              <p class="text-1">Haz de tus pausas algo memorable y revitalizante.</p>
              <div class="text-start">
                <BtButton 
                  :colorBtn="'pink-btn'" 
                  :buttonText="'Quiero reservar'" 
                  class="me-2"
                  data-tally-open="mZJkbB" 
                  data-tally-layout="modal" 
                  data-tally-width="800"
                />
                <BtButton :colorBtn="'green-btn'" :buttonText="'Tengo dudas'" :router="'#faqs'"/>
              </div>
            </div>
            <div class="col-lg-5 col-2"></div>
          </div>
        </div>
      </div>
      <div class="cta-img-cont">
        <div>
          <img src="../assets/img/cta_img.png" alt="" class="cta-img">
        </div>
      </div>
      <div class="invertir-y">
        <img src="../assets/bg/white-bg.png" alt="">
      </div>
    </div>
    <div id="faqs" class="container text-center">
      <h2 class="poppins-2 mb-5">Resuelve tus <br><span class="sed-2-green">dudas</span></h2>
      <AccordionDudas/>
      <BtButton 
        :colorBtn="'pink-btn'" 
        :buttonText="'Quiero reservar mi experiencia'" 
        class="mt-3"
        data-tally-open="mZJkbB" 
        data-tally-layout="modal" 
        data-tally-width="800"/>
    </div>
    <div id="equipobt" class="container">
      <div class="container turq-box">
        <div 
          class="row"
          data-aos="fade-right" 
          data-aos-easing="ease-in-sine" 
          data-aos-offset="400" 
          data-aos-duration="700"
          >
          <div class="col-lg-7 col-12">
            <h2 class="poppins-2"><span class="sed-2-pink">Sé parte de BreakTime</span><br>¿Te gustaría ofrecer tus servicios?</h2>
            <p class="text-3">Para poder evaluar tu interés en colaborar con nosotros y ofrecer los mejores servicios a nuestros clientes, por favor complete el siguiente formulario. Nos pondremos en contacto a la brevedad.</p>
            <BtButton 
            :colorBtn="'green-btn'" 
            :buttonText="'Completar formulario'" 
            class="mt-3"
            data-tally-open="mBBWbe"
            data-tally-layout="modal"
            data-tally-width="800"
            />
          </div>
        </div>
      </div>
      <div 
        class="tshirt-cont d-lg-block d-none"
        data-aos="fade-left" 
        data-aos-easing="ease-in-sine" 
        data-aos-offset="400" 
        data-aos-duration="700"
      >
        <img src="../assets/img/tshirt.png" alt="" class="img-fluid">
      </div>
    </div>
  </div>

</template>

<script>

//import WhatsAppBtn from '../components/WhatsAppBtn.vue'
import BtButtonVertical from '../components/BtButtonVertical.vue'
import BtButton from '../components/BtButton.vue'
import AccordionTaller from '../components/AccordionTaller'
import AccordionOtro from '../components/AccordionOtro'
//import CarouselTestimonios from '../components/CarouselTestimonios'
import AccordionDudas from '../components/AccordionDudas'

export default {
  name: 'HomeView',
  components: {
    BtButtonVertical,
    //WhatsAppBtn,
    BtButton,
    AccordionOtro,
    AccordionTaller,
    //CarouselTestimonios,
    AccordionDudas,
  },
  mounted() {
    
  }
}
</script>
